'use client'

import { CMS_INSTANCE } from '@/lib/api'
import { useRouter } from '@/navigation'
import useAuth from '@/store/auth'
import useFpd from '@/store/fpd'
import useMeta from '@/store/meta'
import { findParentWithDataAttribute } from '@/utils/dom-utils'
import { pushCustomEvent } from '@/utils/logAnalyticsEvent'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import { useLocale, useTranslations } from 'next-intl'
import { useReCaptcha } from 'next-recaptcha-v3'
import { usePathname, useSearchParams } from 'next/navigation'
import { SnackbarProvider } from 'notistack'
import { useEffect, useState } from 'react'
import { SWRConfig } from 'swr'
// import InjectGTM from '@/lib/gtm'

const ClientStoreInitializer = ({ meta, children }) => {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const locale = useLocale()
  const __t = useTranslations()
  const { executeRecaptcha } = useReCaptcha()
  // const [isGoogle, setIsGoogle] = useState(false)

  let dateFormats = {
    year: 'YYYY',
    monthAndYear: 'MMMM YYYY',
    keyboardDate: 'DD/MM/YYYY',
  }
  if (locale === 'th') {
    require('dayjs/locale/th')
    dayjs.extend(buddhistEra)
    dayjs.locale('th')
    dateFormats = {
      year: 'YYYY', // Year when select day => month => this year (BBBB didnt work)
      monthAndYear: 'MMMM BBBB', // Year show in select modal as dropdown (BBBB work)
      keyboardDate: 'DD/MM/YYYY', // year show in field (BBBB didnt work)
    }
  }

  useEffect(() => {
    useMeta.setState({
      metaSettings: meta.settings,
      metaInfo: meta.meta_info,
      centerGroups: meta.center_groups?.filter((x) => x.title),
      centers: meta.centers,
      taxonomies: meta.taxonomies,
      seoRedirect: meta.seo_redirect,
      metaIsLoading: false,
    })
  }, [meta])

  useEffect(() => {
    ;(async () => {
      const userMe = await useAuth.getState().getUserMe({ locale })
      if (!userMe?.success) return
      if (!userMe?.data?.is_basic_filled || !userMe?.data?.is_consent) {
        const destination = searchParams.get('destination')
        if (destination && !destination.includes('/consent-form')) {
          return router.push(`/consent-form?destination=${destination}`)
        }
        if (pathname && !pathname.includes('/consent-form')) {
          const pathnameAndSearch = `${pathname}${window?.location?.search}`
          return router.push(`/consent-form?destination=${pathnameAndSearch}`)
        }
      }
    })()
  }, [pathname])

  // Track all clicks
  useEffect(() => {
    const clickHandler = async (event) => {
      const dataAttr = 'data-bhq-cta'
      // Use the utility function to find the parent with `data-bhq-cta`
      const targetElement = findParentWithDataAttribute(event.target, dataAttr)
      if (targetElement) {
        const userMe = useAuth.getState().userMe
        // if user is not logged in, do not track
        if (!userMe) return
        // Process the click event, log, send to analytics, etc.
        console.log(
          `Event triggered for: ${targetElement.getAttribute(dataAttr)}`
        )
        const eventName = targetElement.getAttribute(dataAttr)
        if (!eventName) return
        const token = await executeRecaptcha('fpd_click_cta')
        // Get previous URL
        const res = await useFpd.getState().logEvent({
          event_name: 'CLICK_CTA',
          event_desc_1: eventName,
          token,
        })
        console.log(res)
      }
    }

    // Add event listener on document
    document.addEventListener('click', clickHandler)

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('click', clickHandler)
    }
  }, [])

  // GA quick hack When route change,
  useEffect(() => {
    const hasSearchParam = searchParams?.toString()?.length > 0
    const url = `${pathname}${!!hasSearchParam ? `?${searchParams}` : ''}`
    //get current user agent
    const userAgent = navigator.userAgent
    //check if user agent is google bot or contains 'moto g power' or '10_15_7'
    const isMotoGPower = userAgent?.includes('moto g power')
    if (isMotoGPower) {
      return
    }

    console.log('next_router_change:', url)
    pushCustomEvent({
      pageType: 'PageView',
      event: 'next_router_change',
      pageTitle: (typeof document !== 'undefined' && document.title) || '',
      pageUrl: url,
    })
  }, [pathname, searchParams])

  //check if google
  // useEffect(() => {
  //   if (typeof navigator !== 'undefined' && navigator.userAgent) {
  //     //check if user agent is google bot or contains 'moto g power' or '10_15_7'
  //     const userAgent = navigator.userAgent
  //     const isGoogleBot = userAgent.includes('Google')
  //     const isMotoGPower = userAgent.includes('moto g power')

  //     if (isGoogleBot || isMotoGPower) {
  //       setIsGoogle(true)
  //     }
  //   }
  // }, [])

  return (
    <SnackbarProvider
      className="tw-my-2 !tw-flex-nowrap"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      iconVariant={{
        error: (
          <i
            aria-hidden="true"
            className="fas fa-triangle-exclamation tw-mr-2 tw-text-lg"
          />
        ),
      }}
      autoHideDuration={3000}
      maxSnack={3}
    >
      {/* {process.env.NODE_ENV == 'production' &&
      process.env.NEXT_PUBLIC_GTM_ID &&
      !isGoogle ? (
        <InjectGTM />
      ) : null} */}

      <SWRConfig
        value={{
          refreshInterval: 0,
          fetcher: (resource) => CMS_INSTANCE(resource).then((d) => d.data),
        }}
      >
        <LocalizationProvider
          dateFormats={dateFormats}
          dateAdapter={AdapterDayjs}
          adapterLocale={locale}
          localeText={{
            okButtonLabel: __t('confirm'),
            cancelButtonLabel: __t('cancel'),
          }}
        >
          {children}
        </LocalizationProvider>
      </SWRConfig>
    </SnackbarProvider>
  )
}

export default ClientStoreInitializer
